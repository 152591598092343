import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import Storage from "@/app/core/util/Storage";
import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/services/DataService";
import InputMask from "react-input-mask";
import { CnpjMask, PhoneMask } from "@/app/core/util/helpers/ContactHelper";
import { ReminderController } from "@/app/core/base/controllers/ReminderController";
import { Typeahead } from "react-bootstrap-typeahead";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { ReminderHelper } from "@/app/core/util/helpers/ReminderHelper";
import DropdownElement from "@/app/core/layouts/main-components/elements/DropdownElement";
import { ReminderContactController } from "@/app/core/base/controllers/ReminderContactController";
import { DateTimeNow } from "@/app/core/util/DateTranslater";
import { Autocomplete } from "@/app/core/util/helpers/AutocompleteHelper";

export default function ReminderModal(
  { show, onHide, infos, customerId },
  props,
) {
  const userData = Storage.getUserData();
  const [data, setData] = useState({});
  const [companies, setCompanies] = useState({});
  const [contacts, setContacts] = useState([]);
  const [results, setResults] = useState([]);
  const [contact, setContact] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [query, setQuery] = useState("");
  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  useEffect(() => {
    loadCompanies();
  }, []);

  useEffect(() => {
    setData(infos);
    if (infos && infos.status && infos.status == ReminderHelper.Status.Done) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    if (infos.contacts) {
      setContacts(infos.contacts);
    } else {
      setContacts([]);
    }
  }, [show, infos]);

  function notifyChange() {
    setChange(date.getTime());
  }

  function loadCompanies() {
    CompanyController.List(customerId, (res) => {
      setCompanies(res);
    });
  }

  function save() {
    if (
      ((!data.description || !data.frequency) &&
        data.type != ReminderHelper.ChargeType.Reminder) ||
      (data.type == ReminderHelper.ChargeType.Reminder &&
        (!data.description || !data.sendDate))
    ) {
      toast.error(Message.PendingData);
    } else {
      let contactList = contacts;
      if (
        data.type == ReminderHelper.ChargeType.Reminder &&
        contactList.length == 0
      ) {
        contactList.push({
          name: userData.firstName,
          email: userData.email,
          phone: userData.phone,
        });
      }
      if (contactList.length != 0) {
        let baseData = data;
        baseData.customerId = customerId;
        if(baseData.bankAccount){
          baseData.description += `
          
          Dados de pagamento: ${baseData.bankAccount}
          `;
        }
        setLoading(true);
        if (data.id) {
          ReminderController.Update(baseData, (res) => {
            checkContacts(res);
            end();
          });
        } else {
          ReminderController.Insert(baseData, (res) => {
            checkContacts(res);
            end();
          });
        }
      } else {
        toast.error("Adicione ao menos 1 contato para receber as cobranças");
      }
    }
  }

  function end() {
    setTimeout(() => {
      toast.success(Message.Saved);
      setLoading(false);
      onHide();
    }, 400);
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
    if (evt.target.name == "companyName") {
      autocomplete(evt.target.value);
    }
  }

  function autocomplete(value) {
    setResults(Autocomplete(value, companies, "name"));
  }

  function handleContactChange(evt, type) {
    setContact(OnDataChange(contact, evt, type));
  }

  function checkContacts(result) {
    let users = contacts.filter((x) => x.id == undefined);
    for (let s in users) {
      ReminderContactController.Insert(
        { ...users[s], reminderId: result.id },
        (res) => { },
      );
    }
  }

  function addContact() {
    if (contact.email) {
      if (!contact.email.includes("@")) {
        toast.error("Insira um email valido");
        return;
      }
    }
    if (data.id) {
      ReminderContactController.Insert(
        { ...contact, reminderId: data.id },
        (res) => {
          loadContacts();
        },
      );
    } else {
      let old = contacts;
      old.push(contact);
      setContacts(old);

      if (data.title == undefined || data.title == "") {
        setData({ ...data, title: contact.name });
      }
    }
    clearContact();
    notifyChange();
  }

  function removeContact(item, index) {
    if (item.id) {
      ReminderContactController.Delete(item.id, (res) => {
        loadContacts();
      });
    } else {
      let old = contacts;
      delete old[index];
      setContacts(old);
    }
    clearContact();
    notifyChange();
  }

  function loadContacts() {
    ReminderContactController.Get(data.id, (res) => setContacts(res));
  }

  function clearContact() {
    setContact({
      name: "",
      email: "",
      whatsapp: "",
    });
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Cobrança"
      onSave={() => save()}
      onSubmit={() => save()}
      isLoading={loading}
    >
      <div className="form-group">
        <span>Tipo de mensagem</span>
        <select
          type="text"
          className="form-control"
          disabled={disabled}
          name="type"
          value={data && data.type}
          onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
        >
          <option>Selecione</option>
          {ReminderHelper.ChargeTypeList.map((item) => (
            <option
              value={item.value}
              selected={data && data.type == item.value ? true : false}
            >
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <div className="block block-bordered">
        <div className="block-header">
          <div className="block-title">Contatos</div>
          <div className="block-tools">
            <DropdownElement
              submit={() => addContact()}
              icon="far fa-user"
              className="mt-3"
              style={{ minWidth: 240, maxWidth: "100%", height: 300 }}
              title={"Adicionar contato"}
              button={"Adicionar"}
            >
              <div className="form-group">
                <span>Nome</span>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  name="name"
                  value={contact && contact.name}
                  onChange={(evt) => handleContactChange(evt)}
                />
              </div>
              <div className="form-group">
                <span>Email</span>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  name="email"
                  value={contact && contact.email}
                  onChange={(evt) => handleContactChange(evt)}
                />
              </div>
              <div className="form-group">
                <span>WhatsApp</span>
                <InputMask
                  mask="(99) 99999-9999"
                  maskChar=""
                  type="text"
                  className="form-control"
                  id="advanced-filter-document"
                  name="phone"
                  placeholder="11 99999-9999"
                  value={contact && contact.phone}
                  onChange={(event) => {
                    handleContactChange({
                      target: {
                        name: "phone",
                        value: event.target.value.replace(/\D/g, ""),
                      },
                    });
                  }}
                />
              </div>
            </DropdownElement>
          </div>
        </div>
        <div className="block-content">
          <div className="row">
            {change &&
              contacts &&
              contacts.map((item, index) => (
                <div
                  class="block block-rounded block-bordered border-success block-link-pop col-md-8"
                  href="javascript:void(0)"
                >
                  <div class="block-content block-content-full d-flex align-items-center ">
                    <img
                      class="img-avatar img-avatar48"
                      src="assets/media/avatars/avatar12.jpg"
                      alt=""
                    />
                    <div class="ml-2 text-left">
                      <p class="font-w600 mb-0">{item.name}</p>
                      <p class="font-size-sm font-italic text-muted mb-0">
                        {item.phone && (
                          <span>
                            <i className="si si-screen-smartphone mr-3" />{" "}
                            {PhoneMask(item.phone)}
                          </span>
                        )}
                        {item.email && (
                          <span>
                            <i className="si si-envelope" /> {item.email}
                          </span>
                        )}
                      </p>
                    </div>
                    <button
                      className="btn btn-dual btn-sm text-danger"
                      onClick={() => removeContact(item, index)}
                    >
                      <i className="si si-close"></i>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {data && data.type != ReminderHelper.ChargeType.Reminder ? (
        <div className="form-group">
          <span>Empresa</span>
          {data && data.company != undefined ? (
            <div>
              <b>{data.company && data.company.name}</b> -{" "}
              {data.company && CnpjMask(data.company.doc)}
            </div>
          ) : (
            <div>
              <input
                className="form-control"
                name="companyName"
                value={data && data.companyName}
                onChange={(evt) => handleChange(evt)}
              />
              <ul
                style={{
                  borderRadius: "3px",
                  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                  background: "rgba(255, 255, 255, 0.9)",
                  padding: "2px 0",
                  fontSize: "90%",
                  position: "fixed",
                  overflow: "auto",
                  marginTop: 10,
                  width: "90%",
                  maxHeight: "300px", // TODO: don't cheat, let it flow to the bottom
                }}
              >
                {results &&
                  results.map((option) => {
                    return (
                      <li
                        className="m-3 cursor"
                        onClick={() =>
                          setData({
                            ...data,
                            companyName: `${option.name} - ${CnpjMask(option.doc)}`,
                            title:
                              data.title == undefined || data.title == ""
                                ? option.name
                                : data.title,
                          }) | setResults([])
                        }
                      >
                        {option.name} - {CnpjMask(option.doc)}
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
      ) : (
        ""
      )}

      <div className="form-group">
        <span>Título</span>
        <input
          className="form-control"
          disabled={disabled}
          name="title"
          value={data && data.title}
          onChange={(evt) => handleChange(evt)}
        />
      </div>


      {data && data.type === ReminderHelper.ChargeType.Doc ?
        <div className="form-group">
          <span>Frases sugeridas (Opcional)</span>
          <select
            className="form-control"
            disabled={disabled}
            onChange={(evt) => setData({ ...data, description: evt.target.value })}>
            <option>Selecione</option>
            <option>Prezado cliente precisamos que seja enviado os documentos XXXXXX</option>
            <option>Aos cuidados de XXXXX Favor enviar as documentações: XXXXX</option>
            <option>Precisamos que nos envie os seguintes documentos: XXXX</option>

          </select>
        </div>
        : data && data.type === ReminderHelper.ChargeType.Money ?
          <div className="form-group">
            <span>Frases sugeridas (Opcional)</span>
            <select
              className="form-control"
              disabled={disabled}
              onChange={(evt) => setData({ ...data, description: evt.target.value })}>
              <option>Selecione</option>
              <option>Prezado cliente favor anexar o comprovante do valor referente a: XXXXXX</option>
              <option>Aos cuidados de XXXXX. Favor enviar o comprovante: XXXXX</option>
              <option>Precisamos que nos envie o comprovante de pagamento: XXXX</option>
            </select>
          </div>
          : ""
      }
      <div className="form-group">
        <span>Mensagem</span>
        <textarea
          className="form-control"
          disabled={disabled}
          name="description"
          value={data && data.description}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
      {data && data.type === ReminderHelper.ChargeType.Money ? <div>
        <div class="form-group">
          <label class="d-block">Deseja adicionar dados bancários? (Essa informação aparecerá no seu lembrete)</label>
          <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" class="custom-control-input" id="example-rd-custom-inline1" name="example-rd-custom-inline" onChange={evt => setData({...data, showAccount: true})}/>
              <label class="custom-control-label" for="example-rd-custom-inline1">Sim</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" class="custom-control-input" id="example-rd-custom-inline2" name="example-rd-custom-inline" onChange={evt => setData({...data, showAccount: false})}/>
              <label class="custom-control-label" for="example-rd-custom-inline2">Não</label>
          </div>

          {data && data.showAccount ? (
            <div className="form-group">
            <span>Descreva a forma de pagamento</span>
            <textarea
              className="form-control"
              disabled={disabled}
              name="bankAccount"
              value={data && data.bankAccount}
              onChange={(evt) => handleChange(evt)}
            />
          </div>
          ):""}
      </div>
      </div>:""}
      {data && data.type != ReminderHelper.ChargeType.Reminder ? (
        <div className="form-group">
          <span>Upload obrigatório</span>
          <select
            className="form-control"
            disabled={disabled}
            name="uploadRequired"
            value={data && data.uploadRequired}
            onChange={(evt) => handleChange(evt, FieldTypeObj.Bool)}
          >
            <option>Selecione</option>
            <option
              value={true}
              selected={data && data.uploadRequired == true ? true : false}
            >
              Sim
            </option>
            <option
              value={false}
              selected={data && data.uploadRequired == false ? true : false}
            >
              Não
            </option>
          </select>
        </div>
      ) : (
        ""
      )}
      {data && data.type != ReminderHelper.ChargeType.Reminder ? (
        <div className="form-group">
          <span>Frequencia de envio</span>
          <select
            className="form-control"
            disabled={disabled}
            name="frequency"
            value={data && data.frequency}
            onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
          >
            <option>Selecione</option>
            <option
              value={1}
              selected={data && data.frequency == 1 ? true : false}
            >
              Envio unico
            </option>
            <option
              value={7}
              selected={data && data.frequency == 7 ? true : false}
            >
              A cada 7 dias
            </option>
            <option
              value={15}
              selected={data && data.frequency == 15 ? true : false}
            >
              A cada 15 dias
            </option>
            <option
              value={30}
              selected={data && data.frequency == 30 ? true : false}
            >
              A cada 30 dias
            </option>
          </select>
        </div>
      ) : (
        ""
      )}
      {data && data.files && data.files.length > 0 ? (
        <div className="block block-bordered border-2x border-success">
          <div className="block-header">
            <div className="block-title">Arquivos enviados pelo contato</div>
            <div className="block-tools"></div>
          </div>
          <div className="ml-2 mr-2 mt-2 mb-3">
            {data.files.map((doc) => {
              return (
                <div className="block block-bordered border-2x">
                  <div className="block-header">
                    <div className="block-title">{doc.name}</div>
                    <div className="block-tools">
                      <button
                        className="btn btn-sm btn-dual"
                        onClick={() => window.open(doc.url, "_blank")}
                      >
                        <i className="far fa-eye"></i> Visualizar
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="form-group">
        <span>Começar a enviar em:</span>
        <input
          className="form-control"
          type="datetime-local"
          min={DateTimeNow()}
          disabled={disabled}
          name="sendDate"
          value={data && data.sendDate}
          onChange={(evt) => handleChange(evt)}
        />
      </div>
    </Modal>
  );
}
