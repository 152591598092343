import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "@/assets/img/logo.jpg";
import { ReminderController } from "@/app/core/base/controllers/ReminderController";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import { ReminderHelper } from "@/app/core/util/helpers/ReminderHelper";
import { ReminderFileController } from "@/app/core/base/controllers/ReminderFileController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import ReminderRouter from "../../reminder";
import Storage from "@/app/core/util/Storage";

export default function ChargeComponent(props) {
  const [step, setStep] = useState(1);
  const [logo, setLogo] = useState("");
  const [data, setData] = useState({});
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(Storage.getUserData());
  const inputFile = useRef(null);

  function onUploadClick() {
    inputFile.current.click();
  }

  useEffect(() => {}, [step]);

  useEffect(() => {
    // setLogo(Logo);
    loadReminder();
  }, []);

  function loadReminder() {
    ReminderController.Get(props.match.params.key, (res) => {
      setData(res);
      CustomerSelect(res.customerId, (result) => {
        if (
          result !== null &&
          result.avatarImages !== undefined &&
          result.avatarImages.length > 0
        ) {
          setLogo(result.avatarImages[0].url);
        } else {
          setLogo(Logo);
        }
      });
      if(userData == null){
        
      }
    });
  }

  function changeStep() {
    switch (step) {
      case 1:
        return <div className="content"></div>;
      case 2:
        return <div className="content"></div>;
      default:
        return <div className="content"></div>;
    }
  }

  function sendBack() {
    ReminderController.Response(data.id, (res) => {
      toast.success(Message.Saved);
      loadReminder();
    });
  }

  async function uploadFile(fl) {
    setLoading(true);
    var formData = new FormData();
    for (let s in fl) {
      formData.append("file", fl[s]);
    }

    await ReminderFileController.Upload(formData, data.id, (res) => {
      toast.info("Upload concluído");
      setLoading(false);
      loadReminder();
    });
  }

  return (
    <div className="">
      <div className="row no-gutters justify-content-center bg-body-dark">
        <div className="hero-static col-sm-10 col-md-8 col-xl-6 d-flex align-items-center p-2 px-sm-0">
          <div className="block block-rounded block-fx-pop w-100 mb-0 overflow-hidden">
            <div className="block-content">
              {userData && userData.level === 5 ? (
                <div className="block block-fx-pop block-bordered border-info mt-5">
                  <div className="block-header">
                    <div className="block-title">
                      <i className="far fa-check-circle text-info mr-2" /> Esse
                      é o modelo de lembrete que seu cliente receberá
                      <br />
                      <small>Fique tranquilo, o nome da Hasa não aparece</small>
                    </div>
                    <div className="block-tools"></div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {data &&
              data.status === ReminderHelper.Status.Done &&
              userData == null ? (
                <div className="block block-fx-pop block-bordered border-success mt-5">
                  <div className="block-header">
                    <div className="block-title">
                      <i className="far fa-check-circle text-success mr-2" />{" "}
                      Você já respondeu esse lembrete
                    </div>
                    <div className="block-tools"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="text-center">
                <img src={logo} width="150px" />
              </div>
              {step && changeStep()}
            </div>
            <div className="block-content">
              <div className="text-center">
                <h3 className="text-muted">
                  {data && data.type == ReminderHelper.ChargeType.Money
                    ? "Enviar comprovante de Pagamento"
                    : "Enviar documento"}
                </h3>
                <p style={{ whiteSpace: "pre-line" }}>
                  {data && data.description}
                </p>
                {data && data.type != ReminderHelper.ChargeType.Reminder ? (
                  <div
                    class="dropzone dz-clickable mt-3 cursor mb-2"
                    onClick={() => onUploadClick()}
                  >
                    <div class="dz-default dz-message">
                      <i className="si si-cloud-upload fa-2x" />
                      <br />
                      <span>Clique aqui para fazer upload</span>
                      <br />
                      <small>O arquivo precisa ser uma imagem ou um PDF</small>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {loading === true ? (
                  <div className="text-center">
                    <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {data && data.files && data.files.length > 0 ? (
                <div>
                  <span>Arquivos</span>
                  {data.files.map((doc) => {
                    return (
                      <div className="block block-bordered border-2x">
                        <div className="block-header">
                          <div className="block-title">{doc.name}</div>
                          <div className="block-tools">
                            <button
                              className="btn btn-sm btn-dual"
                              onClick={() => window.open(doc.url, "_blank")}
                            >
                              <i className="far fa-eye"></i> Visualizar
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
              <button
                className="btn btn-warning btn-square btn-block mt-2 mb-4"
                disabled={
                  (data &&
                    data.type == ReminderHelper.ChargeType.Doc &&
                    data.uploadRequired == true &&
                    data.files &&
                    data.files.length == 0) ||
                  data.status == ReminderHelper.Status.Done ||
                  data.status == ReminderHelper.Status.Canceled
                    ? true
                    : false
                }
                onClick={() => sendBack()}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
        <ToastContainer className="toastr" />
        <input
          type="file"
          ref={inputFile}
          onChange={(event) => {
            uploadFile(event.currentTarget.files);
          }}
          style={{ visibility: "hidden", width: 10 }}
        />
      </div>
    </div>
  );
}
