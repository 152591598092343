import {
  CustomerPicture,
  CustomerSelect,
} from "@/app/core/base/controllers/CustomerController";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState, useRef } from "react";
import CarouselCard from "./components/Carousel";
import ManagerCard from "./components/ManagerCard";
import TaskCard from "./components/TaskCard";
import ExigencyCardReport from "./components/ExigencyCardReport";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import CompaniesCard from "./components/CompaniesCard";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import BudgetCard from "./components/BudgetCard";
import { toast } from "react-toastify";
import ToDoCard from "@/app/core/layouts/main-components/customer/ToDoCard";

export default function HomeComponent({ props }) {
  const userData = Storage.getUserData();
  const [picture, setPicture] = useState(null);
  const [data, setData] = useState({});
  const [exigencies, setExigencies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const inputFile = useRef(null);

  function onUploadClick() {
    inputFile.current.click();
  }

  useEffect(() => {
    getCustomerData();
    loadCustomerExigencies();
    loadCompanies();
    loadBudgets();
  }, []);

  function loadCustomerExigencies() {
    OrganListController().SelectByCustomer(
      props.match.params.key,
      (res) => setExigencies(res),
      (err) => {
        console.log("Erro api", err);
      },
    );
  }

  function loadCompanies() {
    CompanyController.List(props.match.params.key, (res) => setCompanies(res));
  }
  function loadBudgets() {
    setBudgets([]);
    ProcessController().CustomerBudgets(props.match.params.key, (res) => {
      setBudgets(
        res.filter((x) => x.budgetStatus == BudgetStatus.WaitingApproval),
      );
    });
  }

  function getCustomerData() {
    CustomerSelect(props.match.params.key, (res) => {
      console.log("API response", res);
      setData(res);

      if (res.avatarImages.length > 0) {
        setPicture(res.avatarImages[0].url);
      }
    });
  }

  function uploadFile(files) {
    var formData = new FormData();
    formData.append("file", files[0]);

    CustomerPicture(data.id, formData, (res) => {
      toast.success("Salvo com sucesso");
      getCustomerData();
    });
  }

  return (
    <div className="content mr-0 pl-0 pr-0">
      <div className="row mr-0 pr-0">
        <div className="col-md-12 row mb-3">
          <img
            className="img-avatar img-avatar96 img-avatar-thumb text-center"
            src={
              data && picture != null
                ? picture
                : "assets/media/avatars/avatar10.jpg"
            }
            style={{ textAlign: "center" }}
            onClick={(evt) => onUploadClick()}
            // alt="Add logo"
            title="Personalize com seu logo aqui"
          />
          <button
            className="btn btn-white btn-rounded btn-sm"
            style={{ marginLeft: -25, marginTop: 75 }}
            onClick={() => onUploadClick()}
          >
            <i className="fa fa-camera" />
          </button>
          <h3 className="mb-0 ml-3 mt-4">
            <strong className="font-w700">
              Seja bem vindo! {userData && userData.firstName}
            </strong>
            <br />
            <small className="text-muted">
              Código de cliente {data && data.customerCode}
            </small>
          </h3>
        </div>
        <div className="col-md-6 ml-0 mr-0">
          {/* Slider */}
          <div style={{ width: "100%", height: 430 }}>
            <CarouselCard />
          </div>
        </div>
        <div className="col-md-6 row ml-0 mr-0">
          <div className="col-md-6 ml-0 mr-0 pr-1 pl-1">
            <BudgetCard
              budgets={budgets}
              reload={() => loadBudgets() | getCustomerData()}
              customerId={data && data.id}
            />
          </div>
          <div
            className="col-md-6 ml-0 mr-0 pr-1 pl-1 cursor"
            onClick={() => (window.location = `#/reminders`)}
          >
            <div
              className="block block-fx-pop bg-white block-roundedMax mb-3 pb-3 ml-1"
              style={{ height: 210 }}
            >
              <div className="block-header">
                <div className="block-title">
                  <i className="si si-calendar fa-2x"></i>
                </div>
                <div className="block-tools">
                  <i
                    className="si si-question"
                    title="Tenha acesso a nossa ferramenta incrível para facilitar a cobrança de documentos e valores dos seus clientes"
                  />
                </div>
              </div>
              <div className="block-content">
                <h2 className="mb-1">Agenda de Cobranças</h2>8 lembretes ativos
              </div>
            </div>
          </div>
          <div className="col-md-6 ml-0 mr-0 pr-1 pl-1 cursor">
            <CompaniesCard companies={companies} />
          </div>
          <div className="col-md-6 ml-0 mr-0 pr-1 pl-1">
            <ExigencyCardReport
              exigencies={exigencies}
              reload={() => loadCustomerExigencies()}
            />
          </div>
        </div>
        <div className="col-md-9">
          {/* Tabelas */}
          {data && (
            <TaskCard
              customerId={props.match.params.key}
              data={data && data}
              exigencies={exigencies}
            />
          )}
        </div>
        <div className="col-md-3">
          <ManagerCard customerId={props.match.params.key} />
          <a className="btn btn-warning btn-block" target="_blank" href="#/drafter/2">Elaborador de contratos</a>
          <ToDoCard customerId={props.match.params.key} />
        </div>
      </div>
      <input
        type="file"
        ref={inputFile}
        accept="image/*"
        onChange={(event) => {
          uploadFile(event.currentTarget.files);
        }}
        style={{ visibility: "hidden", width: 10 }}
      />
    </div>
  );
}
