import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";
import Axios from "axios";

export function Search(query, userId, response) {
  BaseService(
    {
      method: "GET",
      url: `global?query=${query}&userId=${userId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      console.error("Search", err);
      toast.error(HandleErrors(err));
    },
  );
}

export function Distribute(response) {
  BaseService(
    {
      method: "GET",
      url: `process/run`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function ProcessDistribute(response) {
  BaseService(
    {
      method: "GET",
      url: `process/distribute-process`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function CustomerGeralReports(customerId, response) {
  BaseService(
    {
      method: "GET",
      url: `global/customer/reports/${customerId}`,
    },
    (res) => {
      response(res);
    },
    (err) => {
      toast.error(HandleErrors(err));
    },
  );
}

export function GetAddress(cep, res) {
  Axios.get(`https://viacep.com.br/ws/${cep}/json`, {})
    .then(function (response) {
      let location = {};
      if (response.data.logradouro) {
        location.street = `${response.data.logradouro}`;
      }
      if (response.data.bairro) {
        location.neighborhood = `${response.data.bairro}`;
      }
      if (response.data.localidade) {
        location.city = `${response.data.localidade}`;
      }

      res({ ...location, zipcode: cep, postalCode:cep });
    })
    .catch(function (error) {
      if (error) {
      }
    });
}
