import React, { useEffect, useRef, useState } from "react";
import Logo from "@/assets/img/logo.jpg";
import Axios from "axios";
import Storage from "@/app/core/util/Storage";
import {
    CPFValidator,
    CivilState,
    CivilStateObj,
    CivilStateTranslate,
    Communion,
    CommunionObj,
    CommunionTranslate,
} from "@/app/core/util/helpers/UserHelper";
import {
    Page,
    Text,
    View,
    Document,
    Image,
    StyleSheet,
    BlobProvider,
    Font,
    renderToStream,
} from "@react-pdf/renderer";

import {
    ContractInsert,
    UploadContract,
} from "@/app/core/base/controllers/ContractController";
import { toast } from "react-toastify";
import {
    CepMask,
    CnpjMask,
    CpfMask,
} from "@/app/core/util/helpers/ContactHelper";
import AutomationsController from "@/app/core/base/controllers/AutomationsController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import ReportHelper from "@/app/core/util/helpers/ReportHelper";

export default function ModeloSLU({ customer, data }) {
    const [myDoc, setMyDoc] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [hasCertificate, setHasCertificate] = useState(false);
    const [customerType, setCustomerType] = useState(0);
    const date = new Date();
    const inputFile = useRef(null);
    const userData = Storage.getUserData();
    const automations = AutomationsController();
    const customerHelper = CustomerHelper();
    function onUploadClick() {
        inputFile.current.click();
    }

    const monthNames = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];
    let day = date.getDate(),
        month = date.getMonth() + 1,
        year = date.getFullYear();
    // Font.register({
    //   family: "Oswald",
    //   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
    // });

    const styles = StyleSheet.create({
        page: {
            padding: 30,
            fontSize: 12,
            lineHeight: 1.5,
            backgroundColor: '#ffffff',
        },
        section: {
            marginBottom: 10,
        },
        row: {
            display: "inline",
            whiteSpace: "nowrap",
        },
        title: {
            fontSize: 16,
            marginBottom: 10,
            textAlign: 'center',
            fontWeight: 'bold',
        },
        textRight: {
            fontSize: 16,
            marginBottom: 10,
            textAlign: 'right',
            fontWeight: 'bold',
        },
        clauseTitle: {
            fontSize: 14,
            marginTop: 15,
            marginBottom: 5,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        text: {
            fontSize: 12,
            textAlign: 'justify',
            margin: 12
        },
        textCenter: {
            fontSize: 12,
            textAlign: 'center',
            margin: 12
        },
        celula: {
            fontSize: 12,
            textAlign: 'justify',
            border: 1,
            width: "33%",
            padding: 5,
            borderColor: "#000"
        },
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableCol: {
            width: "33%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCell: {
            margin: "auto",
            marginTop: 5,
            fontSize: 10
        }
    });

    useEffect(() => {
        createContract();
    }, [customer]);

    function createContract() {
        setMyDoc(
            <Document>
                <Page style={styles.page}>
                    <View style={styles.section}>
                        <Text style={styles.title}>CONTRATO DE CONSTITUIÇÃO DE SOCIEDADE LIMITADA</Text>
                        <Text style={styles.title}>UNIPESSOAL</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.title}>{data && data.name} LTDA</Text>
                    </View>


                    {/* Sócios inicio */}
                    {data && data.partners && data.partners.map(item => (
                        <View style={styles.section}>
                            <Text style={styles.text}>
                                {item.name}, {item.nationality},
                                {CivilStateTranslate(item.civilState)}
                                {item.civilState != CivilStateObj.Single ? `sob ${CommunionTranslate(item.communion)}` : ""},
                                nascido em {item.birthYear}, {item.jobTitle}, RG nº. {item.doc} SSP/SP e CPF (MF) nº. {CpfMask(item.stateId)},
                                residente e domiciliado a Rua {item.street}, nº {item.number}, Bairro {item.neighborhood}, {item.city} – {item.state} - CEP {item.postalCode}.
                            </Text>
                            <Text style={styles.text}>
                                Por este instrumento constitui uma Sociedade Limitada Unipessoal, que regerá pelas cláusulas e condições seguintes, nos termos do parágrafo primeiro do artigo 1.052 do código civil de 2002 e nas omissões, pela legislação específica que disciplina essa forma societária.
                            </Text>
                        </View>
                    ))}
                    {/* Sócios Fim */}

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA PRIMEIRA</Text>
                        <Text style={styles.text}>
                            A sociedade limitada unipessoal girará sob a denominação social de XXXXXXXXXXXXXXXXX, nome fantasia de {data && data.name}, e seu uso será obrigatório em todas as operações sociais da sociedade.
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA SEGUNDA</Text>
                        <Text style={styles.text}>
                            O sócio declara que a sociedade limitada unipessoal se enquadra como Microempresa/Empresa de Pequeno Porte, nos termos da Lei Complementar nº 123, de 14 de dezembro de 2006, e que não se enquadra em qualquer das hipóteses de exclusão relacionadas no § 4º do art. 3º da mencionada lei. (art. 3º, I, LC nº 123, de 2006).
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA TERCEIRA</Text>
                        <Text style={styles.text}>
                            A sociedade limitada unipessoal terá sua sede à Rua {data && data.street}, nº {data && data.number}, Bairro {data && data.neighborhood}, {data && data.city} – {data && data.state} - CEP {data && data.postalCode}, podendo abrir filiais ou sucursais em qualquer ponto do território nacional, obedecendo às disposições vigentes.
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA QUARTA</Text>
                        <Text style={styles.text}>
                            O objetivo da sociedade limitada unipessoal será {data && data.description}.
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA QUINTA</Text>
                        <Text style={styles.text}>
                            O	capital social é de R$ {data && data.capital && MoneyMask(data.capital)} (XXXXXXX mil reais) divididos em XXXXXX (XXXXXX  mil) quotas no valor nominal de R$ 1,00 (um real) cada uma, totalmente integralizado, neste ato em moeda corrente do país, ficando o sócio {data && data.partners && data.partners[0] && data.partners[0].name} detentor de sua totalidade.
                        </Text>
                        <Text style={styles.text}>
                            Parágrafo Único: A responsabilidade do sócio é restrita ao valor de suas quotas, respondendo solidariamente pela integralização do Capital Social. (art. 1052, CC/2002).
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA SEXTA</Text>
                        <Text style={styles.text}>
                        O	prazo de duração da sociedade limitada unipessoal será por tempo indeterminado.
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA SÉTIMA</Text>
                        <Text style={styles.text}>
                            A administração será representada por {data && data.partners && data.partners[0] && data.partners[0].name} isoladamente, ativa e passivamente, judicial e extrajudicial, ficando, entretanto, vedado o uso da firma em negócios alheios aos fins sociais.
                        </Text>
                        <Text style={styles.text}>
                            Parágrafo Único:- Na ausência ou impedimento do sócio administrador, todas as funções inerentes ao seu cargo, serão acumuladas por outra pessoa, sendo que em caso algum a interferência de terceiros, serão aceitas salvo autorização reciprocamente consentida pela parte. 
                        </Text>

                    </View>

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA OITAVA</Text>
                        <Text style={styles.text}>
                        O	uso da firma será feito pelo sócio {data && data.partners && data.partners[0] && data.partners[0].name}, sempre nos negócios que digam respeito aos interesses sociais, sendo proibido seu uso para fins estranhos como endosso de favores, cartas de fiança e outros documentos não análogos que acarretarem responsabilidade para a sociedade limitada unipessoal.
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA NONA</Text>
                        <Text style={styles.text}>
                        O sócio {data && data.partners && data.partners[0] && data.partners[0].name} terá direito a uma retirada mensal a título de pró-labore, em valor a ser fixado a cada mês de acordo com a participação de suas quotas, mas sempre dentro da legislação do Imposto de Renda e será levado a uma conta de DESPESAS ADMINISTRATIVAS.
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA DÉCIMA</Text>
                        <Text style={styles.text}>
                        Todo dia 31 de dezembro de cada ano será procedido um balanço do exercício, sendo que os lucros ou prejuízos verificados serão distribuídos ou suportados pelo sócio na proporção de suas quotas de capital. 

                        </Text>
                        <Text style={styles.text}>
Parágrafo único: A sociedade poderá levantar balanços intermediários, semestrais ou em períodos menores, com o objetivo de distribuir lucros antecipados a seu sócio antes do encerramento do exercício social, observando a contribuição ou participação do sócio.
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA DÉCIMA PRIMEIRA</Text>
                        <Text style={styles.text}>
                        No caso de falecimento do sócio a sociedade limitada unipessoal não será extinta, levantando-se um balanço especial nesta data e se convier aos herdeiros do Pré-morto, será lavrado um novo contrato com a inclusão destes com os direitos legais ou então, os herdeiros receberão todos seus haveres apurados até o balanço especial, em 10 (dez) prestações iguais e sucessivamente vencendo-se a primeira após 60 (sessenta) dias da data do Balanço Especial ou de acordo com o que se combinar na ocasião do evento.
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text style={styles.clauseTitle}>CLÁUSULA DÉCIMA SEGUNDA</Text>
                        <Text style={styles.text}>
                        O Administrador declara, sob as penas da Lei, de que não está impedido de exercer a administração da sociedade, por lei especial, ou em virtude de condenação criminal, ou por se encontrar sob os efeitos dela, a pena que vede, ainda que temporariamente, o acesso a cargos públicos, ou por crime falimentar, de prevaricação, peita ou suborno, concussão, peculato, ou contra a economia popular, contra o sistema financeiro nacional, contra normas de defesa da concorrência, contra as relações de consumo, fé pública, ou a propriedade.
                        </Text>
                        <Text style={styles.text}>
                            Fica eleito o foro desta Comarca – SP para qualquer ação fundada neste Instrumento de Contrato Social.
                        </Text>
                        <Text style={styles.text}>
                        Por este instrumento, assina o presente em 1 (uma) via e posteriormente para validade será registrado e arquivado na Junta comercial do estado de São Paulo.
                        </Text>
                        <Text style={styles.textRight}>
                            São Paulo, {date.getDate()} de {ReportHelper().translateMonth(date.getMonth())} de {date.getFullYear()}.
                        </Text>
                    </View>

                    {data && data.partners && data.partners.map(item => (
                        <View style={styles.section}>
                            <Text style={styles.textCenter}>
                                ________________________________________________________
                            </Text>
                            <Text style={styles.clauseTitle}>{item.name}</Text>
                        </View>
                    ))}
                    <View style={styles.section}>
                        <Text style={styles.textCenter}>
                            ________________________________________________________
                        </Text>
                        <Text style={styles.clauseTitle}>(Assinatura de advogado)</Text>
                    </View>


                </Page>
            </Document>
        );
    }

    function uploadFile(files) {
        var formData = new FormData();
        formData.append("file", files[0]);
        UploadContract(userData.customerId, formData, 1, (res) => {
            toast.success("Salvo com sucesso, bem vindo!");
            setTimeout(() => {
                window.location = `#/customer-profile/${userData.customerId}`;
            }, 500);
        });
    }

    function signature(blob, url, loading, error) {
        if (loading === true && blob === null) {
            toast.error(
                "Ocorreu um erro ao gerar o contrato. Por favor entre em contato com o suporte da Hasa",
            );
        } else if (blob !== null) {
            var reader = new FileReader();
            if (hasCertificate === true) {
                if (customerType === customerHelper.Type.Undefined) {
                    toast.error("Selecione o tipo de certificado para prosseguir");
                    return;
                } else {
                    toast.info("Preparando contrato...");
                }
            }
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                var base64data = reader.result;
                uploadSigned(base64data);
            };
        } else {
            toast.info("Gerando o contrato");
        }
    }

    async function uploadSigned(base) {
        const body = {
            document: {
                path: `/Contrato-de-prestacao.pdf`,
                content_base64: base,
                customerType: customerType,
            },
        };

        ContractInsert(body.document, (res) => {
            toast.info("Siga o processo de assinatura do contrato");
            // window.open(res.signatureUrl, "_blank");
            window.location = res.signatureUrl;
        });
    }

    return (
        <div>
            {myDoc && (
                <BlobProvider document={myDoc}>
                    {({ blob, url, loading, error }) => {
                        // Do whatever you need with blob here
                        return (
                            <div>
                                <div className="text-center">
                                    <a
                                        className="btn btn-white"
                                        id="sign-contract"
                                        href={url}
                                    // onClick={() => signature(blob, url, loading, error)}
                                    >
                                        <i className="fa fa-file"></i> Contrato
                                    </a>
                                </div>

                            </div>
                        );
                    }}
                </BlobProvider>
            )}
        </div>
    );
}
