import React from "react";

export default function CustomerHelper() {
  const StatusList = [
    { name: "Não definido", value: 0, color: "secondary" },
    { name: "Pendente", value: 1, color: "primary" },
    { name: "Pendente Hasa", value: 2, color: "warning" },
    { name: "Ativo", value: 3, color: "success" },
    { name: "Bloqueado", value: 4, color: "danger" },
  ];

  const Status = {
    Undefined: 0,
    Pending: 1,
    PendingHasa: 2,
    Active: 3,
    Blocked: 4,
  };

  const ReportType = {
    total: 0,
    actives: 1,
    inactives: 2,
    blocked: 3,
    oportunities: 4,
    conversions: 5,
    partners: 6,
    idle: 7,
    pending: 8,
    oportunitiesRessurgence: 9,
  };

  const Type = {
    Undefined: 0,
    Physical: 1,
    LegalPerson: 2,
  };

  const Stage = {
    Lead: 0,
    Qualificado: 1,
    Convertido: 2,
    Perdido: 3,
    Reativado: 4,
  };

  const StageList = [
    { name: "Retenção", value: 0 },
    { name: "Compra inicial", value: 1 },
    { name: "Faturamento", value: 2 },
    { name: "Política de desconto", value: 3 },
    { name: "Reativados", value: 4 },
  ];

  const StageNameList = [
    { name: "Lead", value: 0 },
    { name: "Qualificado", value: 1 },
    { name: "Convertido", value: 2 },
    { name: "Perdido", value: 3 },
  ];

  function StageTranslate(i) {
    let res = StageList.filter((x) => x.value === i);
    if (res.length > 0) {
      return res[0].name;
    } else {
      return "S.I.";
    }
  }

  function StageNameTranslate(i) {
    let res = StageNameList.filter((x) => x.value === i);
    if (res.length > 0) {
      return res[0].name;
    } else {
      return "S.I.";
    }
  }

  function StatusTranslate(i) {
    let res = StatusList.filter((x) => x.value === i);
    if (res.length > 0) {
      return res[0].name;
    } else {
      return "S.I.";
    }
  }

  function ShowStatus(i) {
    if (i === 0) {
      return <span class="badge badge-secondary ml-1 mr-1">Indefinido</span>;
    }
    if (i === 1) {
      return <span class="badge badge-info ml-1 mr-1">Pendente</span>;
    }
    if (i === 2) {
      return <span class="badge badge-warning ml-1 mr-1">Pendente Hasa</span>;
    }
    if (i === 3) {
      return <span class="badge badge-success ml-1 mr-1">Ativo</span>;
    }
    if (i === 4) {
      return <span class="badge badge-danger ml-1 mr-1">Bloqueado</span>;
    }
  }

  function ShowLevel(i, className, text) {
    if (i === 0) {
      return <span></span>;
    } else if (i === 1) {
      return (
        <span className={text}>
          <i
            title="VIP"
            className={"fa fa-handshake fa-2x text-info mr-1 ml-1 " + className}
          />{" "}
          VIP{" "}
        </span>
      );
    } else if (i === 2) {
      return (
        <span className={text}>
          <i
            title="Exclusive"
            className={"si si-rocket fa-2x text-danger mr-1 ml-1 " + className}
          />{" "}
          Exclusive
        </span>
      );
    } else if (i === 4) {
      return (
        <span className={text}>
          <i
            title="Basic"
            className={
              "fa fa-user-tie fa-2x text-danger mr-1 ml-1 " + className
            }
          />{" "}
          Basic
        </span>
      );
    } else if (i === 3) {
      return (
        <span className={text}>
          <i
            title="Elite"
            className={"si si-diamond fa-2x text-danger mr-1 ml-1 " + className}
          />{" "}
          Elite
        </span>
      );
    }
  }

  const CustomerLevel = {
    Normal: 0,
    Vip: 1,
    Exclusive: 2,
  };

  function CustomerLevelTranslate(i) {
    let res = CustomerLevel.filter((x) => x.value === i);
    if (res.length > 0) {
      return res[0].name;
    } else {
      return "S.I.";
    }
  }

  const CustomerLevelList = [
    { name: "Normal", value: 0 },
    { name: "Vip", value: 1 },
    { name: "Exclusive", value: 2 },
  ];

  const CompanyStatus = {
    NotConsulted: 0,
    Consulting: 1,
    Done: 2,
  };

  const SocietyType = {
    SociedadeLimitada: 0,
    SociedadeUnipessoal: 1,
    EmpresarioIndividual: 2,
  };

  const AdministationType = {
    IsoladamenteEEmConjunto: 0,
    ApenasIsoladamente: 1,
    ApenasEmConjunto: 2,
  };

  return {
    StatusList,
    Status,
    StatusTranslate,
    Type,
    ShowStatus,
    ShowLevel,
    CustomerLevel,
    CustomerLevelTranslate,
    CustomerLevelList,
    ReportType,
    CompanyStatus,
    Stage,
    StageList,
    StageTranslate,
    StageNameList,
    StageNameTranslate,
    SocietyType,
    AdministationType,
  };
}
