import React, { useState, useEffect, useRef } from "react";
import Storage from "@/app/core/util/Storage";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import Layout from "@/app/core/layouts/main";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import Table from "@/app/core/components/table/";
import { DateTranslate, ProcessDates } from "@/app/core/util/DateTranslater";
import {
  CustomerNPS,
  CustomerPicture,
  CustomerSelect,
} from "@/app/core/base/controllers/CustomerController";
import UsersCardComponent from "@/app/core/layouts/main-components/user/UsersCard";
import { MotivationalList } from "@/app/core/base/controllers/MotivationalController";
import CustomerContract from "@/app/core/layouts/main-components/documents/contracts/CustomerContract";
import CustomerPayments from "@/app/core/layouts/main-components/financial/CustomerPayments";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import OrganListHelper, {
  OrganStatus,
} from "@/app/core/util/helpers/OrganListHelper";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";
import CustomerDocCard from "@/app/core/layouts/main-components/documents/CustomerDocCard";
import CustomerTimelineModal from "@/app/core/layouts/main-components/customer/CustomerTimelineModal";
import { CustomerTerms } from "@/app/core/base/controllers/TermsController";
import { ContractList } from "@/app/core/base/controllers/ContractController";
import FinancialEntries from "@/app/core/layouts/main-components/financial/FinancialEntriesCard";
import SugestionModal from "@/app/core/layouts/main-components/contact/SugestionModal";
import BudgetsCard from "./BudgetsCard";
import OngoingCard from "./OngoingCard";
import ExigenciesCard from "./ExigenciesCard";
import Logo from "@/assets/img/logo.jpg";
import DeferredCard from "./DeferredCard";
import {
  CepMask,
  CnpjMask,
  CpfMask,
  PhoneMask,
  PhoneTranslate,
  TelphoneMask,
} from "@/app/core/util/helpers/ContactHelper";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import RemovedProcessCard from "./RemovedProcessCard";
import RefusedBudgetsCard from "./RefusedBudgetsCard";
import CheckMobile from "@/app/core/util/Screen";
import { LastBuy } from "@/app/core/base/controllers/ServicesController";
import FollowingCustomerCard from "@/app/core/layouts/main-components/customer/FolloingCustomercard";
import FollowController from "@/app/core/base/controllers/FollowController";
import HomeComponent from "./HomeComponent";
import CustomerNewBudget from "@/app/core/layouts/main-components/budget/CustomerNewBudget";
import ReportController from "@/app/core/base/controllers/ReportController";

export default function CustomerProfile(props) {
  const [data, setData] = useState({});
  const [picture, setPicture] = useState("assets/media/avatars/avatar10.jpg");
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showSugestionModal, setShowSugestionModal] = useState(false);
  const [nps, setNps] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [serviceOrders, setServiceOrders] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [refusedBudgets, setRefusedBudgets] = useState([]);
  const [finished, setFinished] = useState([]);
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [operationData, setOperationData] = useState({});
  const [processes, setProcessess] = useState([]);
  const [processExigencies, setProcessExigencies] = useState([]);
  const [managers, setManagers] = useState([]);
  const [deferred, setDeferred] = useState([]);
  const [returned, setReturned] = useState([]);
  const userData = Storage.getUserData();
  const [motivational, setMotivational] = useState({});
  const [lastBuy, setLastBuy] = useState({});
  const enviroment = process.env.REACT_APP_ENV;
  let screenHeight = CheckMobile();

  const [headerDone, setHeaderDone] = useState({
    id: "DESC",
    processName: "DESC",
    createdDate: "DESC",
    updatedDate: "DESC",
  });
  const processCtrl = ProcessController();
  const organCtrl = OrganListController();
  const organHelper = OrganListHelper();

  const colsProcess = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    {
      header: "Status",
      isSortable: true,
      name: "status",
      switch: {
        0: <span className="badge badge-info">Não iniciado</span>,
        1: <span className="badge badge-primary">Em andamento</span>,
        2: <span className="badge badge-success">Concluído</span>,
      },
    },
    { header: "Criado em", isSortable: false, name: "createdDate" },
    { header: "Últ. Alteração", isSortable: false, name: "updatedDate" },
  ];

  const colsOngoingProcess = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Processo", isSortable: true, name: "processName" },
    {
      header: "Status",
      isSortable: true,
      name: "status",
    },
    { header: "Aprovado em", isSortable: false, name: "createdDate" },
  ];

  const inputFile = useRef(null);

  function onUploadClick() {
    inputFile.current.click();
  }

  useEffect(() => {
    if (userData && userData.level === 5) {
      checkIfItsOK();
    }

    getCustomerData();
    getCustomerSO();
    loadProcessExigencies();
    loadMotivation();
    loadCustomerOrganList();
    loadDeferreds();
    loadLastBuy();
    loadManagers();
    loadReturneds();
    loadNPS();
    loadDiscount();
  }, []);

  function loadNPS() {
    CustomerNPS(props.match.params.key, (res) => setNps(res.nps.toFixed(2)));
  }

  function loadDiscount() {
    ReportController().DiscountCustomerMonth(props.match.params.key, (res) => setDiscount(res.count));
  }

  function loadLastBuy() {
    LastBuy(
      props.match.params.key,
      (res) => {
        console.log("Errorr", res);
        setLastBuy(res);
      },
      (error) => {
        console.log("Errorr", error);
      },
    );
  }

  function loadManagers() {
    FollowController().FollowingCustomer(props.match.params.key, (res) =>
      setManagers(res),
    );
  }

  async function checkIfItsOK() {
    CustomerTerms(userData.customerId, (res) => {
      for (let s in res) {
        if (res[s].response === false && userData.level == 5) {
          window.location = "#/verification";
        }
      }
    });

    ContractList(userData.customerId, (res) => {
      let doneContracts = res.filter((x) => x.status !== 0);
      if (
        doneContracts.length === 0 &&
        enviroment !== "local" &&
        userData.level == 5
      ) {
        window.location = "#/verification";
      }
    });
  }

  async function loadDeferreds() {
    await organCtrl.ListByStatus(
      OrganStatus.Deferred,
      props.match.params.key,
      (res) => {
        for (let s in res) {
          res[s].statusName = organHelper.GetCurrentStatus(res[s], true);
          res[s].processName = res[s].process.processName;
          res[s].serviceName = res[s].selectedService.serviceName;
          res[s].organName = res[s].organ.name;
          res[s].updatedDate = DateTranslate(res[s].updatedDate);
        }
        setDeferred(res);
      },
    );
  }

  async function loadReturneds() {
    await processCtrl.Returned(props.match.params.key, (res) => {
      setReturned(ProcessDates(res));
    });
  }

  function loadCustomerOrganList() {
    organCtrl.SelectByCustomer(props.match.params.key, (res) => {
      setProcessExigencies(res);
    });
  }

  async function loadProcessExigencies() {
    if (userData === null) {
      return;
    }
    await processCtrl.CustomerProcessExigencies(
      userData.customerId !== undefined
        ? userData.customerId
        : props.match.params.key,
      (res) => {
        setProcessess(ProcessDates(res));
      },
    );
  }

  function getCustomerData() {
    CustomerSelect(props.match.params.key, (res) => {
      setData(res);

      if (res.avatarImages.length > 0) {
        setPicture(res.avatarImages[0].url);
      }
    });
  }

  function getCustomerSO() {
    processCtrl.CustomerList(props.match.params.key, (res) => {
      let open = [];

      for (let s in res) {
        res[s].statusName = organHelper.GetCurrentStatus(res[s], true);
        res[s].processName = res[s].process.processName;
        res[s].serviceName = res[s].selectedService.serviceName;
        const item = res[s];
        let exist = open.filter((x) => x.processId === item.processId);

        if (exist.length === 0) {
          let itens = res.filter((x) => x.processId === item.processId);

          let theOne = {};

          itens.sort(function (a, b) {
            if (a.position < b.position) {
              return -1;
            }
            if (a.position > b.position) {
              return 1;
            }
            return 0;
          });

          for (let x in itens) {
            if (itens[x].stage !== 1 && itens[x].stage !== 10) {
              theOne = itens[x];
            }
          }

          item.updatedDate = DateTranslate(item.updatedDate);
          item.createdDate = DateTranslate(item.createdDate);
          open.push(item);
        }
      }

      setServiceOrders(open);
    });

    processCtrl.CustomerDone(props.match.params.key, (res) => {
      let done = [];
      for (let s in res) {
        res[s].updatedDate = DateTranslate(res[s].updatedDate);
        res[s].createdDate = DateTranslate(res[s].createdDate);
        done.push(res[s]);
      }

      setFinished(done);
    });

    processCtrl.CustomerBudgets(props.match.params.key, (res) => {
      let budget = [];
      let refuseds = [];
      for (let s in res) {
        res[s].updatedDate = DateTranslate(res[s].updatedDate);
        res[s].createdDate = DateTranslate(res[s].createdDate);
        if (
          res[s].budgetStatus === 0 ||
          res[s].budgetStatus === 1 ||
          res[s].budgetStatus === 4 ||
          res[s].budgetStatus === 5
        ) {
          res[s].budgetStatusName =
            res[s].budgetStatus === 5
              ? "Aguardando aprovação"
              : "Aguardando Orçamento";
          budget.push(res[s]);
        } else if (res[s].budgetStatus === BudgetStatus.Refused) {
          res[s].budgetStatusName = "Recusado";
          refuseds.push(res[s]);
        }
      }
      setBudgets(budget);
      setRefusedBudgets(refuseds);
    });
  }

  function loadMotivation() {
    MotivationalList((res) => {
      if (res.length > 0) {
        const response = res.filter((x) => x.level === 5);
        let result = shuffleArray(shuffleArray(response));
        console.log("Medidas", result, res);
        if (response.length > 0) {
          setMotivational(result[0]);
        }
      }
    });
  }

  function shuffleArray(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  function uploadFile(files) {
    var formData = new FormData();
    formData.append("file", files[0]);

    CustomerPicture(props.match.params.key, formData, (res) => {
      getCustomerData();
    });
  }

  return (
    <>
      {/* // {false ? ( */}
      {enviroment == "staging" || enviroment == "local" ? (
        <Layout {...props}>
          <HomeComponent props={props} />
        </Layout>
      ) : (
        <Layout {...props}>
          <div className={Storage.getTheme()}>
            <div
              className="bg-image"
              style={
                screenHeight == false
                  ? {
                      backgroundImage: `url(${motivational.url})`,
                    }
                  : { backgroundColor: "#000" }
              }
            >
              <div className="bg-black-25">
                <div className="content content-full">
                  <div className="row py-3">
                    <div className="col-3">
                      {screenHeight == false ? "" : ""}
                    </div>
                    <div className="col-3">
                      {/* <a class="block block-rounded text-center" href="javascript:void(0)">
                      <div class="block-content block-content-full block-content-sm bg-warning border-bottom border-white-op">
                        <p class="font-w600 text-white mb-0">Jose Parker</p>
                      </div>
                      <div class="block-content block-content-full bg-warning">
                        <img class="img-avatar img-avatar-thumb img-avatar-rounded" src="assets/media/avatars/avatar15.jpg" alt="" />
                      </div>
                      <div class="block-content block-content-full">
                        <div class="row gutters-tiny">
                          <div class="col-6">
                            <div class="item item-circle mb-3 mx-auto border border-warning border-2x">
                              <i class="far fa-fw fa-hdd text-warning"></i>
                            </div>
                            <p class="font-size-sm text-muted mb-0">
                              3 Servers
                            </p>
                          </div>
                          <div class="col-6">
                            <div class="item item-circle mb-3 mx-auto border border-warning border-2x">
                              <i class="far fa-fw fa-envelope text-warning"></i>
                            </div>
                            <p class="font-size-sm text-muted mb-0">
                              14 Messages
                            </p>
                          </div>
                        </div>
                      </div>
                    </a> */}
                    </div>
                    <div className={screenHeight == false ? "col-6" : "col-12"}>
                      <div className="py-5 text-center bg-black-50">
                        <a
                          className="img-link"
                          onClick={(evt) => onUploadClick()}
                        >
                          <img
                            className="img-avatar img-avatar96 img-avatar-thumb"
                            src={picture}
                            alt="Personalize com seu logo aqui"
                            title="Personalize com seu logo aqui"
                          />
                        </a>
                        <h1 className="font-w700 my-2 text-white">
                          {userData && userData != null && userData.level === 5
                            ? `Seja bem vindo!`
                            : ``}
                          <br />
                          {userData && userData.level === 5
                            ? `${userData != null && userData.firstName}`
                            : `${data && data.name}`}
                        </h1>
                        {(userData &&
                          userData.level === 5 &&
                          data &&
                          data.status === 3) ||
                        userData.level !== 5 ? (
                          <h2 className="h4 font-w700 text-white-75">
                            Código de Cliente : {data && data.customerCode}
                          </h2>
                        ) : (
                          ""
                        )}
                        {userData &&
                        userData.level !== 5 &&
                        data.id != undefined ? (
                          <FollowingCustomerCard
                            presentation={true}
                            customerId={data && data.id}
                          />
                        ) : (
                          ""
                        )}
                        {userData && userData.level != 5 ? (
                          <span className="text-white">
                            Última compra:{" "}
                            {lastBuy &&
                              lastBuy.serviceName &&
                              lastBuy.serviceName}{" "}
                            em{" "}
                            {lastBuy &&
                              lastBuy.acceptedDate &&
                              DateTranslate(lastBuy.acceptedDate)}
                          </span>
                        ) : (
                          ""
                        )}
                        {userData && userData.level !== 5 && data ? (
                          <div className="mb-3">
                            {data && CustomerHelper().ShowStatus(data.status)} -
                            {data &&
                              CustomerHelper().ShowLevel(
                                data.level,
                                "fa-2x",
                                "text-white",
                              )}
                          </div>
                        ) : (
                          ""
                        )}
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setShowSugestionModal(true)}
                        >
                          Sugestões
                        </button>
                        {userData && userData.level !== 5 ? (
                          <div className="d-flex row">
                            <span className="col-6 text-white text-left pl-5">
                              {discount} compras na política
                            </span>
                            <span className="col-6  text-white text-right pr-5">
                              {nps.toLocaleString()} NPS média
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content row">
              {managers && managers.length > 0 ? (
                <div className="col-12">
                  <div className="content-heading mb-0">
                    <h4 className="mb-0">
                      {managers.length == 1
                        ? "Este é o gerente da sua conta!"
                        : "Estes são os gerentes da sua conta!"}
                    </h4>
                  </div>
                  {managers.map((item) => (
                    <a
                      class="block block-rounded block-bordered border-warning border-2x block-link-pop"
                      href="javascript:void(0)"
                    >
                      <div class="block-content block-content-full ">
                        <div class="row">
                          <div className="col-6 d-flex align-items-center">
                            <img
                              class="img-avatar img-avatar48 img-avatar-thumb"
                              src={
                                item.user &&
                                item.user.avatarImages &&
                                item.user.avatarImages.length > 0
                                  ? item.user.avatarImages[0].url
                                  : "assets/media/avatars/avatar11.jpg"
                              }
                              alt=""
                            />
                            <p class="font-w600 mb-0 ml-2">
                              {item.user && item.user.firstName}{" "}
                              {item.user && item.user.lastName}
                            </p>
                            <br />
                            <p class="ml-2 font-size-sm font-italic text-muted mb-0">
                              Gerente de relacionamento
                            </p>
                          </div>
                          <div className="col-6 text-right">
                            <a
                              className="btn btn-info btn-square"
                              href={`mailto:${item.user && item.user.email}`}
                            >
                              <i className="si si-envelope-letter mr-2" />{" "}
                              {item.user && item.user.email}
                            </a>
                            <a
                              className="btn btn-success btn-square"
                              href={`tel:${item.user && item.user.phone}`}
                            >
                              <i className="fa fa-phone mr-2" />{" "}
                              {item.user &&
                                item.user.phone &&
                                TelphoneMask(item.user.phone)}
                            </a>
                          </div>
                          <div className="text-right"></div>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              ) : (
                ""
              )}
              <div className="col-lg-12 col-md-12 col-md-12">
                <Tabs>
                  <div className="block block-rounded block-bordered">
                    <div className="" style={{ padding: 10 }}>
                      {userData && userData.level !== 5 ? (
                        <Tab>
                          <button
                            style={{ margin: 10 }}
                            className="btn  btn-light"
                          >
                            <i className="si si-book-open mr-1" /> Informações
                          </button>
                        </Tab>
                      ) : (
                        ""
                      )}
                      {userData && userData.level !== 5 ? (
                        <Tab>
                          <button
                            style={{ margin: 10 }}
                            className="btn  btn-light"
                          >
                            <i className="si si-users mr-1" /> Usuários da
                            plataforma
                          </button>
                        </Tab>
                      ) : (
                        ""
                      )}

                      <Tab>
                        <button
                          style={{ margin: 10 }}
                          className="btn  btn-light"
                        >
                          <i className="si si-diamond mr-1" /> Orçamentos em
                          aberto ({budgets.length})
                        </button>
                      </Tab>

                      <Tab>
                        <button
                          style={{ margin: 10 }}
                          className="btn  btn-light"
                        >
                          <i className="far fa-clock mr-1" /> Serviços em
                          Andamento
                        </button>
                      </Tab>
                      <Tab>
                        <button
                          style={{ margin: 10 }}
                          className="btn  btn-light"
                        >
                          <i className="fa fa-tasks mr-1" /> Exigências em
                          aberto (
                          {processExigencies && processExigencies.length})
                        </button>
                      </Tab>
                      <Tab
                        disabled={
                          userData && userData.level === 5 ? true : false
                        }
                      >
                        <button
                          // disabled={
                          //   enviroment == "staging" || enviroment == "local"
                          //     ? false
                          //     : true
                          //   // userData && userData.level === 5 ? true : false
                          // }
                          style={{ margin: 10 }}
                          className="btn  btn-light"
                        >
                          <i className="far fa-check-circle mr-1" /> Concluídos
                        </button>
                      </Tab>

                      {userData && userData.level !== 5 ? (
                        <Tab>
                          <button
                            style={{ margin: 10 }}
                            className="btn  btn-light"
                          >
                            <i className="fa fa-file-signature mr-1" /> Contrato
                          </button>
                        </Tab>
                      ) : (
                        ""
                      )}
                      {/* {userData && userData.level !== 5 ? ( */}
                      {userData &&
                      (userData.level === 7 || userData.level === 10) &&
                      (enviroment == "staging" || enviroment == "local") ? (
                        <Tab>
                          <button
                            style={{ margin: 10 }}
                            className="btn  btn-light"
                          >
                            <i className="si si-credit-card mr-1" /> Cobranças
                          </button>
                        </Tab>
                      ) : (
                        ""
                      )}
                      {userData &&
                      (userData.level === 7 || userData.level === 10) &&
                      (enviroment == "staging" || enviroment == "local") ? (
                        // {userData && userData.level > 6 && (enviroment == "staging" || enviroment == "local") ? (
                        <Tab>
                          <button
                            style={{ margin: 10 }}
                            className="btn  btn-light"
                          >
                            <i className="far fa-money-bill-alt mr-1" /> Conta
                            corrente
                          </button>
                        </Tab>
                      ) : (
                        ""
                      )}
                      {userData && userData.level != 5 ? (
                        <Tab>
                          <button
                            style={{ margin: 10 }}
                            className="btn  btn-light"
                          >
                            <i className="far fa-window-close mr-1" /> Processos
                            excluídos
                          </button>
                        </Tab>
                      ) : (
                        ""
                      )}
                      {userData && userData.level != 5 ? (
                        <Tab>
                          <button
                            style={{ margin: 10 }}
                            className="btn  btn-light"
                          >
                            <i className="si si-bag mr-1" /> Orçamentos
                            recusados
                          </button>
                        </Tab>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {userData && userData.level !== 5 ? (
                    <Panel>
                      <div className="block block-rounded">
                        <div className="block-header">
                          <div className="title">Informações</div>
                          <div className="tools">
                            <button
                              className="btn  btn-outline-info"
                              onClick={() => setShowCustomerModal(true)}
                            >
                              Timeline
                            </button>
                          </div>
                        </div>
                        <div className="block-content">
                          <table className="table">
                            {/* <tr>
                            <td>Última compra</td>
                            <td>
                              {lastBuy && lastBuy.serviceName && lastBuy.serviceName} em {lastBuy && lastBuy.acceptedDate && DateTranslate(lastBuy.acceptedDate)}
                            </td>
                          </tr> */}
                            <tr>
                              <td>Nome</td>
                              <td>{data && data.name}</td>
                            </tr>
                            <tr>
                              <td> Código de Cliente</td>
                              <td>{data && data.customerCode}</td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>{data && data.email}</td>
                            </tr>
                            <tr>
                              <td>Telefone comercial</td>
                              <td>
                                {data && data.phone && TelphoneMask(data.phone)}
                              </td>
                            </tr>
                            <tr>
                              <td>Telefone celular</td>
                              <td>
                                {data &&
                                  data.whatsapp &&
                                  PhoneMask(data.whatsapp)}
                              </td>
                            </tr>
                            <tr>
                              <td>Website</td>
                              <td>{data && data.website && data.website}</td>
                            </tr>
                            <tr>
                              <td>CNPJ</td>
                              <td>
                                {data && data.cnpj && CnpjMask(data.cnpj)}
                              </td>
                            </tr>
                            <tr>
                              <td>CPF</td>
                              <td>{data && data.cpf && CpfMask(data.cpf)}</td>
                            </tr>
                            <tr>
                              <td>Logradouro</td>
                              <td>{data.street}</td>
                            </tr>
                            <tr>
                              <td>Número</td>
                              <td>{data.number}</td>
                            </tr>
                            <tr>
                              <td>Bairro</td>
                              <td>{data.neighborhood}</td>
                            </tr>
                            <tr>
                              <td>Complemento</td>
                              <td>{data.complement}</td>
                            </tr>
                            <tr>
                              <td>CEP</td>
                              <td>{data.zipcode && CepMask(data.zipcode)}</td>
                            </tr>
                            <tr>
                              <td>Local</td>
                              <td>
                                {data && data.city} / {data && data.uf}
                              </td>
                            </tr>
                            <tr>
                              <td>Tipo de conta</td>
                              <td>
                                {data && data.type === 1
                                  ? "Pessoa Física"
                                  : "Pessoa Jurídica"}
                              </td>
                            </tr>
                            <tr>
                              <td>Observações</td>
                              <td>{data && data.observation}</td>
                            </tr>
                          </table>
                          <CustomerDocCard
                            customerId={parseInt(props.match.params.key)}
                          />
                        </div>
                      </div>
                    </Panel>
                  ) : (
                    ""
                  )}
                  {userData && userData.level !== 5 ? (
                    <Panel>
                      <UsersCardComponent customerId={props.match.params.key} />
                    </Panel>
                  ) : (
                    ""
                  )}
                  <Panel>
                    <BudgetsCard
                      getCustomerSO={() => getCustomerSO()}
                      budgets={budgets}
                      refusedBudgets={refusedBudgets}
                      customerId={props.match.params.key}
                    />
                  </Panel>
                  <Panel>
                    <OngoingCard
                      getCustomerSO={() => getCustomerSO()}
                      deferredData={deferred}
                      data={serviceOrders}
                    />
                  </Panel>

                  <Panel>
                    <ExigenciesCard
                      data={processExigencies}
                      reload={() => loadProcessExigencies()}
                    />
                  </Panel>
                  <Panel>
                    <DeferredCard
                      finishedData={finished}
                      returnedData={returned}
                      customerId={props.match.params.key}
                      getCustomerSO={() => getCustomerSO()}
                    />
                  </Panel>

                  {userData && userData.level !== 5 ? (
                    <Panel>
                      <CustomerContract customerId={props.match.params.key} />
                    </Panel>
                  ) : (
                    ""
                  )}
                  {userData &&
                  userData.level !== 5 &&
                  (enviroment == "staging" || enviroment == "local") ? (
                    <Panel>
                      <CustomerPayments customerId={props.match.params.key} />
                    </Panel>
                  ) : (
                    ""
                  )}
                  {userData &&
                  (userData.level === 7 || userData.level === 10) &&
                  (enviroment == "staging" || enviroment == "local") ? (
                    <Panel>
                      <FinancialEntries customerId={props.match.params.key} />
                    </Panel>
                  ) : (
                    ""
                  )}
                  {userData && userData.level != 5 ? (
                    <Panel>
                      <RemovedProcessCard customerId={props.match.params.key} />
                    </Panel>
                  ) : (
                    ""
                  )}
                  {userData && userData.level != 5 ? (
                    <Panel>
                      <RefusedBudgetsCard customerId={props.match.params.key} />
                    </Panel>
                  ) : (
                    ""
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </Layout>
      )}

      <CustomerTimelineModal
        customerId={data && data.id}
        show={showCustomerModal}
        onHide={() => setShowCustomerModal(false) | getCustomerData()}
      />

      <OperationProcessModal
        data={operationData}
        show={showOperationModal}
        submit={(updateBudget) => null}
        onHide={() =>
          setOperationData(null) |
          getCustomerSO() |
          setShowOperationModal(false)
        }
      />

      <SugestionModal
        show={showSugestionModal}
        customer={data}
        onHide={() => setShowSugestionModal(false)}
      />

      <input
        type="file"
        ref={inputFile}
        onChange={(event) => {
          uploadFile(event.currentTarget.files);
        }}
        style={{ visibility: "hidden", width: 10 }}
      />
      <ToastContainer className="toastr" />
    </>
  );
}
